.footer {
    width: 100%;
    height: fit-content;
    background-color: #232323!important;
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 2px solid #292929;
}

.footerText {
    color: #dfdfdf;
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    margin: 0;
    order: 1;
    flex-grow: 3;
}

.footerImage {
    float: left;
    color: #dfdfdf;
    width: 162px;
    height: 36px;
    margin: 15px 10px 10px 10px;
    order: 0;
}

.terms {
    color: #dfdfdf;
    font-size: 14px;
    height: fit-content;
    padding: 10px 20px 10px 10px;
    margin: 0;
    order: 2;
    flex-shrink: 1;
}

.terms a {
    color: #dfdfdf;
}

@media screen and (max-width: 1350px){
    .footerText {
        font-size: 12px;
    }
    .terms {
        font-size: 12px;
    }
}

@media screen and (max-width: 1025px){
    .footerText {
        font-size: 10px;
    }
    .terms {
        font-size: 10px;
    }
}

@media screen and (max-width: 710px){
    .footer {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .terms {
        order: 1;
        font-size: 12px;
        padding: 10px;
        flex-shrink: 3;
    }
    .footerText {
        float: left;
        order: 2;
        padding: 5px;
    }
    .footerImage {
        margin: 5px 20px 5px 20px;
        order: 0;
        width: 135px;
        height: 30px;
    }
}