.navbar {
    justify-content: space-between;
    background-image: url('./covid_header_bg.jpg');
    background-color: transparent!important;
    background-position-x: center;
    background-position-y: top;
    height: 300px;
}

.brand {
    margin-right: 40px;
    margin-left: 10px;
}

.links a {
    color: #dfdfdf;
    margin-right: 56px;
    font-size: 16px;
}

.links a:active {
    color: #888888;
}

.header {
    color: white;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 2px #000000;
}
