.searchContainer {
    text-align: center;
    margin-top: 30px;
    height: fit-content;
}

.searchBar {
    width: 80%;
    padding-left: 10px;
    margin-top: 10px;
}

.searchLabel {
    margin: 0;
    font-size: large;
    background-color: transparent;
}


.tooltip {
    background-color: rgba(255, 100, 100, 0.85);
    padding: 2px 10px;
    color: white;
    border-radius: 3px;
    margin-left: 10px;
}

.searchBarDark {
    width: 80%;
    padding-left: 10px;
    margin-top: 10px;
    background-color: #232323;
    color: #BBBBBB;
}

.dark {
    color: #BBBBBB;
}
