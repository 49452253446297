.darkModeContrainer {
    width: 100%;
    height: 50px;
}

.darkModeForm {
    float: right;
    margin: 20px;
}

.dark {
    color: #BBBBBB;
}