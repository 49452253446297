.dark {
    background-color: #1E1E1E;
    color: #BBBBBB;
}

.spinnerContainer {
    padding-bottom: 90%;
    padding-top: 10%;
    text-align: center;
}

.spinner {
    width: 100px;
    height: 100px;
}

.placeholder {
    height: 1000px;
}